import { createEmptySheet } from "../utils/tools";

export const companyName = "Pessoa";

export const DocTypes = {
  document: "document",
  sheet: "sheet",
  draw: "draw",
  flow: "flow",
  media: "media",
  code: "code",
  api: "api",
  table: "table",
};

export const EmptySheet = createEmptySheet(20, 20);

export const drawerWidth = 250;

export const subDocHeight = "400px";
export const scrollUnit = 400;
