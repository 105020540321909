import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Box,
} from "@mui/material";
// import GoogleSignIn from "./googleSignIn";
import axiosInstance, { baseURL } from "./axiosInstance";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post(
        `${baseURL}auth/login/`,
        {
          grant_type: "password",
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      localStorage.setItem("accessToken", res.data.accessToken);
      navigate("/documents");
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Box>
          {/* <Box>
          <GoogleSignIn />
          </Box> */}
          {error && (
            <Typography color="error" align="center" mb={2}>
              {error}
            </Typography>
          )}
          <Button fullWidth variant="contained" color="primary" type="submit">
            Login
          </Button>
        </form>
        <Typography align="center" mt={2}>
          Don't have an account? <a href="/register">Register here</a>
        </Typography>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default Login;
