import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { Handle, Position } from "@xyflow/react";

export default function BstNode({ id, data }) {
  const [label, setLabel] = useState(data.label);

  const handleLableChange = (event) => {
    setLabel(event.target.value);
    console.log(data);
    data.onChange(id, event.target.value);
  };

  return (
    <Box
      sx={{
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <TextField
        size="small"
        value={label}
        helperText={null}
        onChange={handleLableChange}
        sx={{ width: "100%" }}
      />
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </Box>
  );
}
