import { React, useState } from "react";
// mui
import { RemoveCircle } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";

//custom components
import {
  BstTableColumnTextField,
  BstTableTextField,
} from "./CustomComponents/muiComponents";
import { subDocHeight } from "./constants";
import BstLayout from "./BstLayout";

export const initialRows = [["", "", "", "", "", ""]];
export const initialColumns = [
  "Column 1",
  "Column 2",
  "Column 3",
  "Column 4",
  "Column 5",
  "Column 6",
];

export default function BstTable(props) {
  const {
    subDocument,
    saveDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    fullScreen,
  } = props;

  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const data = subDocument.data;
  const isFullscreen = fullScreen[subDocumentId];

  const colWidth = 200;
  const [columnHeader, setColumnHeader] = useState(data.columns);
  const [table, setTable] = useState(data.rows);

  // Row Operations
  const addRow = () => {
    const colLen = columnHeader.length;
    const newRow = new Array(colLen).fill("");
    const newTable = [...table, newRow];
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  const removeRow = (rowIndex) => {
    const newTable = table.filter((_, index) => index !== rowIndex);
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  // Column operation
  const addColumn = () => {
    const colLen = columnHeader.length;
    const newColumnName = `Column ${colLen + 1}`;
    const newColumns = [...columnHeader, newColumnName];
    setColumnHeader(newColumns);

    // add rows to this column
    const updatedTable = table.map((row) => [...row, ""]);
    setTable(updatedTable);
    saveDocument({ rows: updatedTable, columns: newColumns }, subDocumentId);
  };

  const removeColumn = (columnIndex) => {
    // Remove column header
    const newColumnHeader = columnHeader.filter(
      (_, index) => index !== columnIndex
    );
    setColumnHeader(newColumnHeader);

    // Remove the corresponding column from each row
    const updatedTable = table.map((row) => {
      const newRow = row.filter((_, index) => index !== columnIndex);
      return newRow;
    });
    setTable(updatedTable);
    saveDocument(
      { rows: updatedTable, columns: newColumnHeader },
      subDocumentId
    );
  };

  // cell operation
  const updateColumn = (event, columnIndex) => {
    const text = event.target.value;
    const newColumn = [...columnHeader]; // Create a copy of columnHeader
    newColumn[columnIndex] = text;
    setColumnHeader(newColumn);
    saveDocument({ rows: table, columns: newColumn }, subDocumentId);
  };

  const updateCell = (event, rowIndex, columnIndex) => {
    const text = event.target.value;
    const newTable = table.map((row, i) => {
      if (i === rowIndex) {
        const updatedRow = [...row];
        updatedRow[columnIndex] = text; // Modify the specific cell
        return updatedRow;
      }
      return row;
    });
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            height: isFullscreen ? "85vh" : subDocHeight,
            overflow: "auto",
          }}
        >
          {/* column header */}
          <Box
            sx={{
              display: "flex",
            }}
          >
            {/* Header columns */}
            {columnHeader.map((_, columnIndex) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    width: colWidth + "px",
                    textAlign: "center",
                    alignItems: "center",
                    bgcolor: "#f3f3f3",
                  }}
                >
                  <BstTableColumnTextField
                    value={columnHeader[columnIndex]}
                    onChange={(event) => updateColumn(event, columnIndex)}
                  />
                  <IconButton
                    size="small"
                    onClick={() => removeColumn(columnIndex)}
                  >
                    <RemoveCircle />
                  </IconButton>
                </Box>
              );
            })}
            {/* Add Column button */}
            <IconButton variant="contained" onClick={addColumn}>
              <AddIcon />
            </IconButton>
          </Box>
          {/* table */}
          <Box>
            {table.map((item, rowIndex) => {
              // 2-D item
              return (
                <Box sx={{ display: "flex" }}>
                  {item.map((_, columnIndex) => {
                    return (
                      <Box sx={{ width: colWidth + "px" }}>
                        <BstTableTextField
                          value={table[rowIndex][columnIndex]}
                          onChange={(event) =>
                            updateCell(event, rowIndex, columnIndex)
                          }
                          fullWidth
                        />
                      </Box>
                    );
                  })}
                  <IconButton onClick={() => removeRow(rowIndex)}>
                    <RemoveCircle />
                  </IconButton>
                </Box>
              );
            })}

            {/* Add Row button */}
            <IconButton
              sx={{ width: `${columnHeader.length * colWidth}px` }}
              variant="contained"
              onClick={addRow}
              disableFocusRipple={true}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        {!writePermission && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "transparent",
              zIndex: 10,
            }}
          ></Box>
        )}
      </Box>
    </BstLayout>
  );
}
