import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Modal,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import axiosInstance from "./axiosInstance";

function BstShare(props) {
  const { shareOpen, handleShareClose, documentId } = props;
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState("read");
  const [isSharing, setIsSharing] = useState(false);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  // const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    // Fetch shared users from backend
    const fetchSharedUsers = async () => {
      try {
        setLoadingUsers(true);
        // Replace with your API call
        const response = await axiosInstance.get(
          `/doc/shared-user/${documentId}`
        );
        setSharedUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch shared users:", error);
      } finally {
        setLoadingUsers(false);
      }
    };

    if (shareOpen) {
      fetchSharedUsers();
    }
  }, [shareOpen, documentId]);

  const fetchSharedUsers = async () => {
    try {
      setLoadingUsers(true);
      // Replace with your API call
      const response = await axiosInstance.get(
        `/doc/shared-user/${documentId}`
      );
      setSharedUsers(response.data);
    } catch (error) {
      console.error("Failed to fetch shared users:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleAddUser = async () => {
    setIsSharing(true);
    try {
      // Example API request to add a new user
      await axiosInstance.post(`/doc/share/${documentId}`, {
        shareEmail: email,
        permission: permission,
      });
      fetchSharedUsers();
    } catch (error) {
      console.error("Failed to share document:", error);
    } finally {
      setIsSharing(false);
      //   setOpen(false);
      setEmail("");
      setPermission("read");
    }
  };

  // const handleEditUser = async (user) => {
  //   await axiosInstance.put(`/doc/share/${documentId}/`, {
  //     shareEmail: user.email,
  //     permission: user.permission,
  //   });
  //   fetchSharedUsers();
  // };

  const handleRemoveUser = async (userId) => {
    await axiosInstance.delete(`/doc/share/${documentId}/${userId}`);
    fetchSharedUsers();
  };

  const updateUser = async (documentId, userId, permission) => {
    try {
      await axiosInstance.put(`/doc/share/${documentId}`, {
        permission: permission,
        userId: userId,
      });

      fetchSharedUsers();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSaveEdit = (user) => {
  //   // handleEditUser(user);
  //   setEditingUser(null);
  // };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Modal
        open={shareOpen}
        onClose={handleShareClose}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: 500,
          }}
        >
          <h2 id="share-modal-title">Manage Shared Users</h2>
          {loadingUsers ? (
            <CircularProgress />
          ) : (
            <>
              <List>
                {sharedUsers.map((item) => (
                  <ListItem key={item.id}>
                    <ListItemText
                      primary={item.user?.email}
                      //   secondary={`Permission: ${user.permission}`}
                    />
                    <FormControl sx={{ ml: 2 }}>
                      <Select
                        value={item.permission}
                        onChange={(e) => {
                          setSharedUsers(
                            sharedUsers.map((u) =>
                              u.email === item.email
                                ? { ...u, permission: e.target.value }
                                : u
                            )
                          );
                          updateUser(
                            documentId,
                            item.user?._id,
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value="read">Read</MenuItem>
                        <MenuItem value="write">Write</MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton
                      onClick={() => handleRemoveUser(item.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                    {/* <IconButton
                      onClick={() => setEditingUser(item)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton> */}
                  </ListItem>
                ))}
              </List>
              <TextField
                fullWidth
                label="Enter email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mt: 2 }}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="permission-label">Permission</InputLabel>
                <Select
                  labelId="permission-label"
                  value={permission}
                  label="Permission"
                  onChange={(e) => setPermission(e.target.value)}
                >
                  <MenuItem value="read">Read</MenuItem>
                  <MenuItem value="write">Write</MenuItem>
                </Select>
              </FormControl>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={handleAddUser}
                disabled={isSharing || !email}
              >
                {isSharing ? "Adding..." : "Add User"}
              </Button>
              {/* {editingUser && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Edit email"
                    variant="outlined"
                    value={editingUser.email}
                    disabled
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="edit-permission-label">
                      Permission
                    </InputLabel>
                    <Select
                      labelId="edit-permission-label"
                      value={editingUser.permission}
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          permission: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="read">Read</MenuItem>
                      <MenuItem value="write">Write</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={() => handleSaveEdit(editingUser)}
                  >
                    Save Changes
                  </Button>
                </Box>
              )} */}
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default BstShare;
