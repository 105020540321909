import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import theme from "./theme";
// components
import Login from "./components/login";
import Register from "./components/register";
import Layout from "./components/Layout/layout";
import { ThemeProvider } from "@mui/material";
import Bst from "./components/Bst.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";

function App() {
  const [open, setOpen] = useState(true);
  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/documents"
              element={<Layout open={open} setOpen={setOpen} />}
            />
            <Route path="/documents/:id" element={<Bst />} />
            <Route path="/" element={<Navigate to={"/login"} />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
