import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Slider, Popover, IconButton } from "@mui/material";

import { ChromePicker } from "react-color";
// icons
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ResetIcon from "@mui/icons-material/Replay";
import EraserIcon from "@mui/icons-material/AutoFixNormal";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import axiosInstance from "./axiosInstance";
//custom components
import BstLayout from "./BstLayout";
import { subDocHeight } from "./constants";

const BstDraw = (props) => {
  const {
    subDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    fullScreen,
  } = props;
  const data = useRef(subDocument.data);
  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const isFullscreen = fullScreen[subDocumentId];

  const canvasRef = useRef(null);
  const canvasBodyRef = useRef(null);
  const [context, setContext] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [penWidth, setPenWidth] = useState(5);
  const [eraserWidth, setEraserWidth] = useState(5);
  const [strokeColor, setStrokeColor] = useState("blue");
  const [eraseMode, setEraseMode] = useState(false);
  const [paths, setPaths] = useState([]);
  const [redoPaths, setRedoPaths] = useState([]);
  const [colorAnchorEl, setColorAnchorEl] = useState(null);
  const open = Boolean(colorAnchorEl);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      setContext(ctx);
      canvas.width = 1260;
      canvas.height = 400;

      // Clear canvas before drawing
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Redraw the saved data (if any)
      if (data.current) {
        const img = new Image();
        img.src = data.current; // Use the saved data URL
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
        };
      }
    }
  }, [isExpanded, data]);

  useEffect(() => {
    if (context) {
      context.lineCap = "round";
      context.strokeStyle = strokeColor;
      context.lineWidth = penWidth;
    }
  }, [penWidth, strokeColor, context]);

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    setPaths([
      ...paths,
      context.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      ),
    ]);
    setRedoPaths([]); // Clear redo stack on new draw
    saveCanvasData();
  };

  const handleUndo = () => {
    if (paths.length > 0) {
      const newPaths = [...paths];
      const lastPath = newPaths.pop();
      setRedoPaths([...redoPaths, lastPath]);
      setPaths(newPaths);
      redrawCanvas(newPaths);
      saveCanvasData();
    }
  };

  const handleRedo = () => {
    if (redoPaths.length > 0) {
      const newRedoPaths = [...redoPaths];
      const restoredPath = newRedoPaths.pop();
      setPaths([...paths, restoredPath]);
      setRedoPaths(newRedoPaths);
      redrawCanvas([...paths, restoredPath]);
      saveCanvasData();
    }
  };

  const handleClear = () => {
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setPaths([]);
    setRedoPaths([]);
  };

  const handleReset = () => {
    handleClear();
    saveCanvasData();
  };

  const redrawCanvas = (allPaths) => {
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    allPaths.forEach((path) => {
      context.putImageData(path, 0, 0);
    });
  };

  const handlePenClick = () => {
    setEraseMode(false);
    context.strokeStyle = strokeColor;
    context.lineWidth = penWidth;
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    context.strokeStyle = "#FFFFFF";
    context.lineWidth = eraserWidth;
  };

  const handlePenWidthChange = (event, newValue) => {
    setPenWidth(newValue);
    if (!eraseMode) context.lineWidth = newValue;
  };

  const handleEraserWidthChange = (event, newValue) => {
    setEraserWidth(newValue);
    if (eraseMode) context.lineWidth = newValue;
  };

  const handleColorClick = (event) => {
    setColorAnchorEl(event.currentTarget);
  };

  const handleColorClose = () => {
    setColorAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setStrokeColor(color.hex);
    if (!eraseMode) context.strokeStyle = color.hex;
  };

  const saveCanvasData = async () => {
    try {
      const paths = canvasRef.current.toDataURL();
      data.current = paths;
      await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
        data: paths,
      });
    } catch (error) {
      console.error("Failed to save the document:", error);
    }
  };

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box sx={{ border: "1px solid grey", borderRadius: "10px" }}>
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          sx={{ mb: 1, borderBottom: "1px solid grey" }}
        >
          <Box sx={{ display: "flex" }} onClick={handlePenClick}>
            <Button disabled={eraseMode || !writePermission}>
              <EditIcon />
            </Button>
            <Slider
              value={penWidth}
              onChange={handlePenWidthChange}
              min={1}
              max={20}
              sx={{ width: "100px" }}
              disabled={eraseMode || !writePermission}
            />
          </Box>
          <Box sx={{ display: "flex" }} onClick={handleEraserClick}>
            <Button disabled={!eraseMode || !writePermission}>
              <EraserIcon />
            </Button>
            <Slider
              value={eraserWidth}
              onChange={handleEraserWidthChange}
              min={1}
              max={20}
              sx={{ width: "100px" }}
              disabled={!eraseMode || !writePermission}
            />
          </Box>
          <Button onClick={handleUndo} disabled={!writePermission}>
            <UndoIcon />
          </Button>
          <Button onClick={handleRedo} disabled={!writePermission}>
            <RedoIcon />
          </Button>
          <Button onClick={handleReset} disabled={!writePermission}>
            <ResetIcon />
          </Button>
          <IconButton onClick={handleColorClick} disabled={!writePermission}>
            <ColorLensIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={colorAnchorEl}
            onClose={handleColorClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <ChromePicker
              color={strokeColor}
              onChange={handleColorChange}
              disabled={!writePermission}
            />
          </Popover>
        </Box>
        <Box
          ref={canvasBodyRef}
          sx={{
            position: "relative",
            height: isFullscreen ? "85vh" : subDocHeight,
          }}
        >
          <canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{
              borderRadius: "10px",
              cursor: "crosshair",
              display: "block",
            }}
          />
          {!writePermission && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
                zIndex: 10, // Make sure it overlays on top
              }}
            ></Box>
          )}
        </Box>
      </Box>
    </BstLayout>
  );
};

export default BstDraw;
