import React, { useCallback } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  applyEdgeChanges,
  applyNodeChanges,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BstNode from "./BstNode";

// custom component
import { subDocHeight } from "../constants";
import BstLayout from "../BstLayout";

const nodeTypes = {
  bstNode: BstNode,
};

const BstFlow = (props) => {
  const {
    subDocument,
    writePermission,
    expand,
    saveDocument,
    handlSelectSubDoc,
    fullScreen,
  } = props;
  const data = subDocument.data;
  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const isFullscreen = fullScreen[subDocumentId];

  // const isFirstNodesRender = useRef(true);

  const [nodes, setNodes] = useNodesState(data?.nodes);
  const [edges, setEdges] = useEdgesState(data?.edges);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        const updatedNodes = applyNodeChanges(changes, nds);
        saveDocument({ nodes: updatedNodes, edges: edges }, subDocumentId);
        return updatedNodes;
      });
    },
    [edges, subDocumentId, saveDocument, setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        const updatedEdges = applyEdgeChanges(changes, eds);
        saveDocument({ nodes: nodes, edges: updatedEdges }, subDocumentId);
        return updatedEdges;
      });
    },
    [nodes, subDocumentId, saveDocument, setEdges]
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => {
        const updatedEdges = addEdge(params, eds);
        saveDocument({ nodes: nodes, edges: updatedEdges }, subDocumentId);
        return updatedEdges;
      });
    },
    [nodes, subDocumentId, saveDocument, setEdges]
  );

  const addNode = useCallback(() => {
    const handleLabelChange = (id, label) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, label } } : node
        )
      );
    };
    const newNode = {
      id: (nodes.length + 1).toString(),
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { label: `Node ${nodes.length + 1}`, onChange: handleLabelChange },
      type: "bstNode",
    };
    const updatedNodes = [...nodes, newNode];
    setNodes(updatedNodes);
    saveDocument({ nodes: updatedNodes, edges: edges }, subDocumentId);
  }, [nodes, edges, subDocumentId, setNodes, saveDocument]);

  const onNodesDelete = useCallback(
    (deletedNodes) => {
      const updatedNodes = nodes.filter(
        (n) => !deletedNodes.some((d) => d.id === n.id)
      );
      setNodes(updatedNodes);
      saveDocument({ nodes: updatedNodes, edges: edges }, subDocumentId);
    },
    [nodes, edges, subDocumentId, setNodes, saveDocument]
  );

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <ReactFlowProvider>
        {" "}
        <Button
          onClick={addNode}
          variant="contained"
          color="primary"
          disabled={!writePermission}
          sx={{ mb: 1 }}
        >
          <AddIcon />
        </Button>
        <Box
          sx={{
            width: "100%",
            height: isFullscreen ? "85vh" : subDocHeight,
            border: "1px solid grey",
            borderRadius: "10px",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            // onNodeDoubleClick={onNodeDoubleClick}
            onNodesDelete={onNodesDelete}
            nodeTypes={nodeTypes}
            nodesDraggable={writePermission} // Disable dragging nodes
            nodesConnectable={writePermission} // Disable connecting nodes
            elementsSelectable={writePermission}
          >
            <Controls />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        </Box>
      </ReactFlowProvider>
    </BstLayout>
  );
};

export default BstFlow;
