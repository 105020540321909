import axiosInstance from "./axiosInstance";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
// components
import Quill from "quill";
import { styled } from "@mui/material";
// custom components
import BstLayout from "./BstLayout";
import { subDocHeight } from "./constants";

const TOOLBAR_OPTIONS = [
  [{ font: [] }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["blockquote", "code-block"],
];

const SAVE_DEBOUNCE_INTERVAL = 1000;

// Style for Quill editor with fixed height and scroll
const QuillContainer = styled("div")(({ subDocHeight }) => ({
  overflow: "auto", // Enable scrolling
  border: "1px solid grey",
  borderRadius: 10,
  "& .ql-editor": {
    minHeight: subDocHeight, // Ensure editor content area uses full height
    height: subDocHeight,
  },
  "& .ql-container": {
    border: "none", // Remove border
  },
}));

const BstEditor = forwardRef((props, ref) => {
  const {
    subDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    fullScreen,
  } = props;
  const data = useRef(subDocument.data);
  const subDocumentId = subDocument._id;
  const isFullscreen = fullScreen[subDocumentId];
  const [quill, setQuill] = useState();
  const [saveTimeout, setSaveTimeout] = useState(null);
  const isExpanded = expand[subDocumentId];

  const wrapperRef = useCallback(
    (wrapper) => {
      if (wrapper == null) return;
      wrapper.innerHTML = "";
      const editor = document.createElement("div");
      wrapper.append(editor);
      const q = new Quill(editor, {
        theme: "snow",
        modules: { toolbar: TOOLBAR_OPTIONS },
        keyboard: {
          bindings: {
            bold: {
              key: "B",
              shortKey: true,
              handler: function () {
                q.format("bold", !q.getFormat().bold);
              },
            },
            italic: {
              key: "I",
              shortKey: true,
              handler: function () {
                q.format("italic", !q.getFormat().italic);
              },
            },
            underline: {
              key: "U",
              shortKey: true,
              handler: function () {
                q.format("underline", !q.getFormat().underline);
              },
            },
            redo: {
              key: "Y",
              shortKey: true, // Ctrl+Y or Cmd+Y for redo
              handler: function () {
                console.log("hello");
                q.history.redo(); // Perform redo
              },
            },
          },
        },
      });
      q.setText("Loading...");
      q.setContents(data.current);
      q.history.clear();

      if (writePermission) {
        q.enable();
      } else {
        q.disable();
      }
      setQuill(q);
    },
    [data, writePermission]
  );

  useEffect(() => {
    if (!quill) return;

    const handleRedo = (event) => {
      if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        quill.history.redo();
      }
    };

    document.addEventListener("keydown", handleRedo);

    // Cleanup the  listener on unmount
    return () => {
      document.removeEventListener("keydown", handleRedo);
    };
  }, [quill]);

  useImperativeHandle(ref, () => ({
    focus() {
      if (quill) {
        quill.focus();
      }
    },
    unfocus() {
      if (quill) {
        quill.blur();
      }
    },
  }));

  // Update document
  useEffect(() => {
    const saveDocument = async () => {
      try {
        const contents = quill.getContents();
        data.current = contents;
        await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
          data: contents,
        });
      } catch (error) {
        console.error("Failed to save the document:", error);
      }
    };

    if (quill) {
      const handleChange = () => {
        if (saveTimeout) {
          clearTimeout(saveTimeout);
        }
        const timeout = setTimeout(saveDocument, SAVE_DEBOUNCE_INTERVAL);
        setSaveTimeout(timeout);
      };

      quill.on("text-change", handleChange);
      return () => {
        quill.off("text-change", handleChange);
        if (saveTimeout) {
          clearTimeout(saveTimeout);
        }
      };
    }
  }, [quill, subDocumentId, saveTimeout]);

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <QuillContainer
        subDocHeight={isFullscreen ? "85vh" : subDocHeight}
        ref={wrapperRef}
      ></QuillContainer>
    </BstLayout>
  );
});

export default BstEditor;
