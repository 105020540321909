import React, { useState } from "react";
import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router";
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Box,
} from "@mui/material";
import { ToastContainer } from "react-toastify";

const Register = () => {
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("auth/register", { name, email, password });
      navigate("/login");
    } catch (err) {
      setError("Registration failed");
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Register
        </Typography>
        <form onSubmit={handleRegister}>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Username"
              value={name}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Box>
          {error && (
            <Typography color="error" align="center" mb={2}>
              {error}
            </Typography>
          )}
          <Button fullWidth variant="contained" color="primary" type="submit">
            Register
          </Button>
        </form>
        <Typography align="center" mt={2}>
          Already have an account? <a href="/login">Login here</a>
        </Typography>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default Register;
