import React, { useEffect, useState } from "react";
import axiosInstance, { baseURL } from "./axiosInstance";
// mui
import { Box, IconButton, TextField } from "@mui/material";
import {
  VideoLibrary,
  Image,
  Link as LinkIcon,
  // Save,
} from "@mui/icons-material";
// icons
import ClearIcon from "@mui/icons-material/Clear";
//utils
import { FullScreenContainer } from "./CustomComponents/muiComponents";
import BstHeader from "./BstHeader";

export default function BstMedia(props) {
  const {
    subDocument,
    docType,
    handleHeadingChange,
    handleOpenConfirm,
    writePermission,
    handleExpand,
    expand,
    provided,
    fullScreen,
  } = props;
  const subDocumentId = subDocument._id;
  const [heading, setHeading] = useState(subDocument.heading);
  const isExpanded = expand[subDocumentId];
  const [mediaType, setMediaType] = useState(subDocument.mediaType);
  const isFullscreen = fullScreen[subDocumentId];
  const path =
    subDocument.mediaType !== "ytlink"
      ? baseURL + subDocument.mediaPath
      : subDocument.mediaPath;
  const [preview, setPreview] = useState(path);
  const [mediaPath, setMediaPath] = useState(path);

  const handleMediaTypeChange = (type) => {
    setMediaType(type);
    setMediaPath(""); // Clear the previous input
    setPreview(null); // Clear previous preview
  };

  useEffect(() => {
    if (mediaType === "image" || mediaType === "video") {
      document.getElementById("fileInput")?.click();
    }
  }, [mediaType]);

  useEffect(() => {
    const updateDocument = async (formData) => {
      try {
        await axiosInstance.postForm(
          `/sub-doc/upload/${subDocumentId}`,
          formData
        );
      } catch (error) {
        console.error("Error saving media:", error.response.data);
      }
    };
    const formData = new FormData();
    if (mediaType === "ytlink") {
      formData.append("mediaPath", mediaPath); // append file if it's an image
    } else {
      formData.append("file", mediaPath); // append file if it's an image
    }
    formData.append("mediaType", mediaType);
    updateDocument(formData);
  }, [mediaPath, mediaType, subDocumentId]);

  const handleInputChange = (e) => {
    let path = e.target.value;
    if (mediaType === "ytlink") {
      const videoId = path.split("=")[1];
      path = `https://www.youtube.com/embed/${videoId}`;
    }
    setMediaPath(path);
    setPreview(path); // Preview for link or video URL
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMediaPath(file);
      setPreview(URL.createObjectURL(file)); // Preview for image/video file
    }
  };

  const updateDocument = async (formData) => {
    try {
      const response = await axiosInstance.postForm(
        `/sub-doc/upload/${subDocumentId}`,
        formData
      );
      console.log("Media saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving media:", error.response.data);
    }
  };

  const handleClear = async () => {
    setMediaPath("");
    setMediaType("");
    setPreview("");
    const formData = new FormData();
    formData.append("mediaType", "");
    updateDocument(formData);
  };

  return (
    <FullScreenContainer isFullscreen={isFullscreen}>
      <Box sx={{ p: 1 }}>
        <BstHeader
          heading={heading}
          docType={docType}
          writePermission={writePermission}
          setHeading={setHeading}
          handleHeadingChange={handleHeadingChange}
          subDocumentId={subDocumentId}
          isExpanded={isExpanded}
          handleOpenConfirm={handleOpenConfirm}
          handleExpand={handleExpand}
          isFullscreen={isFullscreen}
          provided={provided}
        />

        {(isExpanded || isFullscreen) && (
          <Box>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <IconButton
                disabled={!writePermission}
                onClick={() => handleMediaTypeChange("video")}
                color={mediaType === "video" ? "primary" : "default"}
              >
                <VideoLibrary />
              </IconButton>
              <IconButton
                disabled={!writePermission}
                onClick={() => handleMediaTypeChange("image")}
                color={mediaType === "image" ? "primary" : "default"}
              >
                <Image />
              </IconButton>
              <IconButton
                disabled={!writePermission}
                onClick={() => handleMediaTypeChange("ytlink")}
                color={mediaType === "ytlink" ? "primary" : "default"}
              >
                <LinkIcon />
              </IconButton>
              <IconButton
                disabled={!writePermission}
                onClick={handleClear}
                color={"primary"}
              >
                <ClearIcon />
              </IconButton>
            </Box>

            {/* Hidden File Input */}
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept={mediaType === "image" ? "image/*" : "video/*"}
            />

            <Box>
              {mediaType === "image" && preview && (
                <img
                  src={preview}
                  alt="Selected"
                  style={{ marginTop: "20px", maxWidth: "100%" }}
                />
              )}
              {mediaType === "ytlink" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Enter link URL"
                  value={mediaPath}
                  onChange={handleInputChange}
                  sx={{ marginBottom: 2 }}
                />
              )}

              {/* Preview for video link */}
              {preview && mediaType === "video" && (
                <video controls style={{ marginTop: "20px", maxWidth: "100%" }}>
                  <source src={preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              {/* Preview for video */}
              {preview && mediaType === "ytlink" && (
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",
                    maxWidth: "100%",
                    background: "#000",
                  }}
                >
                  <iframe
                    src={preview}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube"
                  />
                </div>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </FullScreenContainer>
  );
}
