import React, { useState } from "react";

// components
import "quill/dist/quill.snow.css";
import { Box, styled } from "@mui/material";
import Spreadsheet from "react-spreadsheet";

// custom component
import { FullScreenContainer } from "./CustomComponents/muiComponents";
import { subDocHeight } from "./constants";
import BstHeader from "./BstHeader";

const SpreadSheetContainer = styled("div")(({ subDocHeight }) => ({
  overflow: "auto", // Enable scrolling
  border: "1px solid grey",
  borderRadius: 10,
  "& .Spreadsheet": {
    minHeight: subDocHeight, // Ensure editor content area uses full height
    height: subDocHeight,
    width: "1000px",
  },
}));

export default function BSTEditor(props) {
  const {
    subDocument,
    docType,
    saveDocument,
    handleHeadingChange,
    handleOpenConfirm,
    writePermission,
    handleExpand,
    expand,
    handlSelectSubDoc,
    provided,
  } = props;
  const data = subDocument.data;
  const subDocumentId = subDocument._id;
  const [heading, setHeading] = useState(subDocument.heading);
  const isExpanded = expand[subDocumentId];
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <FullScreenContainer
      onClick={() =>
        handlSelectSubDoc({ heading, data, docType, isExpanded }, subDocument)
      }
      isFullscreen={isFullscreen}
    >
      <Box sx={{ p: 1 }}>
        <BstHeader
          heading={heading}
          docType={docType}
          writePermission={writePermission}
          setHeading={setHeading}
          handleHeadingChange={handleHeadingChange}
          subDocumentId={subDocumentId}
          isExpanded={isExpanded}
          handleOpenConfirm={handleOpenConfirm}
          handleExpand={handleExpand}
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          provided={provided}
        />
        {(isExpanded || isFullscreen) && (
          <SpreadSheetContainer
            subDocHeight={isFullscreen ? "80vh" : subDocHeight}
          >
            <Box sx={{ position: "relative" }}>
              <Spreadsheet
                data={subDocument.data}
                onChange={(data) => saveDocument(data, subDocumentId)}
              />
              {!writePermission && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "transparent",
                    zIndex: 10, // Make sure it overlays on top
                  }}
                ></Box>
              )}
            </Box>
          </SpreadSheetContainer>
        )}
      </Box>
    </FullScreenContainer>
  );
}
