import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Avatar,
  Popover,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
// icons
// icons
import ShareIcon from "@mui/icons-material/Share";

import axiosInstance from "./axiosInstance";
import { useNavigate } from "react-router";

export default function BstMainHeader(props) {
  const {
    documentName,
    setDocumentName,
    writePermission,
    documentId,
    userData,
    handleShareOpen,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update document name
  const handleNameChange = async (e) => {
    const newName = e.target.value;
    setDocumentName(newName);
    try {
      await axiosInstance.put(`/doc/${documentId}/`, { name: newName });
    } catch (error) {
      console.error("Failed to update document name:", error);
    }
  };

  // logout
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  return (
    <Box sx={{ ml: 5, mt: 1, display: "flex" }}>
      <Box sx={{ width: "90%" }}>
        <TextField
          sx={{ mt: 1, width: "375px" }}
          placeholder="Main Heading"
          variant="standard"
          value={documentName}
          disabled={!writePermission}
          onChange={(newValue) => handleNameChange(newValue)}
        />
      </Box>
      <Tooltip title={"share"} arrow>
        <IconButton
          sx={{ ml: 1 }}
          color="primary"
          onClick={handleShareOpen}
          disabled={!writePermission}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Avatar
        sx={{
          ml: 2,
          backgroundSize: "small",
          cursor: "pointer",
          bgcolor: "pink.main",
        }}
        onClick={handleAvatarClick}
      >
        {userData?.name?.charAt(0).toUpperCase()}
      </Avatar>

      {/* Popover with User Info */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{userData?.name}</Typography>
          <Typography variant="body2">{userData?.email}</Typography>
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            color="secondary"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
