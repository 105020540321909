import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import BstDeleteConfirm from "../BstDeleteConfirm";

const DocumentList = ({
  open,
  documents,
  selectedDocId,
  selectedDocIndex,
  handleDelete,
  handleDocumentClick,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);

  const handleOpenConfirm = (id) => {
    setDocToDelete(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setDocToDelete(null);
  };

  const handleConfirmDelete = () => {
    handleDelete(docToDelete);
    handleCloseConfirm();
  };

  return (
    <Box>
      <List sx={{ p: 1 }}>
        {documents.map((item, index) => (
          <ListItem
            key={item._id}
            disablePadding
            sx={{
              display: "block",
              // border: selectedDocIndex === index ? "1px solid grey" : "none",
              borderRadius: 1,
              marginTop: 1,
              backgroundColor:
                selectedDocId === item._id ? "#f1f1f1" : "transparent",
              boxShadow:
                index === selectedDocIndex
                  ? "0px 2px 2px rgba(0, 0, 0, 0.5)"
                  : "none",
            }}
          >
            <ListItemButton
              onClick={() => handleDocumentClick(item._id)}
              sx={{
                justifyContent: open ? "initial" : "center",
                p: 1,
              }}
            >
              <Tooltip title={item.name} arrow>
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: open ? 1 : 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    p: 0,
                    m: 0,
                  }}
                />
              </Tooltip>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering ListItemButton's onClick
                  handleOpenConfirm(item._id);
                }}
                sx={{ m: 0, ml: 1, p: 0 }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <BstDeleteConfirm
        confirmOpen={confirmOpen}
        handleCloseConfirm={handleCloseConfirm}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};

export default DocumentList;
