import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai";
import { Box } from "@mui/material";
import { CustomLanguageSelect } from "./CustomComponents/muiComponents";
import { subDocHeight } from "./constants";
import detectLang from "lang-detector";

// Importing language modes
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-rust";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import BstLayout from "./BstLayout";

// Helper function to detect the language
const detectLanguage = (code) => {
  return detectLang(code);
};

const BstCode = (props) => {
  const {
    subDocument,
    saveDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    fullScreen,
  } = props;

  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const [data, setData] = useState(subDocument.data);
  const isFullscreen = fullScreen[subDocumentId];

  // State for selected language
  const [language, setLanguage] = useState("javascript");

  useEffect(() => {
    // Detect the language automatically when the component loads
    try {
      const detectedLanguage = detectLanguage(data);
      // Set AceEditor mode based on detected language
      const aceLanguage = mapLanguageToAce(detectedLanguage);
      setLanguage(aceLanguage);
    } catch (e) {
      console.error(e);
    }
  }, [data]);

  // Helper to map highlight.js language to Ace mode
  const mapLanguageToAce = (hljsLang) => {
    switch (hljsLang) {
      case "JavaScript":
        return "javascript";
      case "Python":
        return "python";
      case "Java":
        return "java";
      case "C++":
        return "c_cpp";
      case "rust":
        return "rust";
      case "c":
        return "c_cpp";
      case "Go":
        return "golang";
      case "HTML":
        return "html";
      case "CSS":
        return "css";
      case "PHP":
        return "php";
      default:
        return "javascript";
    }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box>
        <CustomLanguageSelect
          language={language}
          handleLanguageChange={handleLanguageChange}
        />

        <Box sx={{ position: "relative" }}>
          <AceEditor
            value={data}
            mode={language} // Dynamically set the language mode
            theme="monokai"
            name="code-editor"
            onChange={(newValue) => {
              setData(newValue);
              saveDocument(newValue, subDocumentId);
            }}
            editorProps={{ $blockScrolling: true }}
            setOptions={{ useWorker: false }}
            width="100%"
            height={isFullscreen ? "85vh" : subDocHeight}
          />
          {!writePermission && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "transparent",
                zIndex: 10,
              }}
            ></Box>
          )}
        </Box>
      </Box>
    </BstLayout>
  );
};

export default BstCode;
