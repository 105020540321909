import {
  blue,
  brown,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: red[500],
    },
    brown: {
      main: brown[300],
    },
    yellow: {
      main: yellow[300],
    },
    green: {
      main: green[300],
    },
    orange: {
      main: orange[300],
    },
    black: {
      main: grey[300],
    },
    purple: {
      main: purple[300],
    },
    pink: {
      main: pink[300],
    },
  },
});

export default responsiveFontSizes(theme);
