import axios from "axios";
import { toast } from "react-toastify";
import { Navigate } from "react-router";

export const baseURL = process.env.REACT_APP_BASE_URL; // "http://3.109.187.243:3002/api/v1/";

const axiosInstance = axios.create({
  baseURL: baseURL, // Replace with your actual API base URL
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Include credentials with requests
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // Get the token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 400) {
      console.log(error.response);
      toast.error(error.response.data?.message, {
        position: "bottom-right",
        autoClose: 500,
        progress: false,
        hideProgressBar: true,
      });
    }
    if (error?.response?.status === 401) {
      localStorage.removeItem("accessToken");
      <Navigate to={"/login"} replace />;
    }
    if (error?.response?.status === 404) {
      localStorage.removeItem("accessToken");
      <Navigate to={"/login"} replace />;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
