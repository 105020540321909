import React, { useEffect } from "react";
import { Box, Fab, IconButton, Tooltip } from "@mui/material";

// icons
import ApiIcon from "@mui/icons-material/Api";
import CodeIcon from "@mui/icons-material/Code";
import DrawIcon from "@mui/icons-material/Draw";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
// import PostAddIcon from "@mui/icons-material/PostAdd";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import GridOnIcon from "@mui/icons-material/GridOn";

// constants

import { EmptySheet, DocTypes } from "./constants";
import { initialColumns, initialRows } from "./BstTable";

const handleCreateNewSubDoc = (docType, handleCreateSubDoc) => {
  switch (docType) {
    case DocTypes.document:
      return handleCreateSubDoc({}, "", DocTypes.document);
    case DocTypes.sheet:
      return handleCreateSubDoc(EmptySheet, "", DocTypes.sheet);
    case DocTypes.draw:
      return handleCreateSubDoc([], "", DocTypes.draw);
    case DocTypes.flow:
      return handleCreateSubDoc({ nodes: [], edges: [] }, "", DocTypes.flow);
    case DocTypes.code:
      return handleCreateSubDoc({}, "", DocTypes.code);
    case DocTypes.media:
      return handleCreateSubDoc({}, "", DocTypes.media);
    case DocTypes.api:
      return handleCreateSubDoc(
        {
          method: "GET",
          url: "",
          headers: [{ key: "", value: "" }],
          queryParams: [{ key: "", value: "" }],
          jsonBody: "",
          response: "",
        },
        "",
        DocTypes.api
      );
    case DocTypes.table:
      console.log(handleCreateSubDoc);
      return handleCreateSubDoc(
        { rows: initialRows, columns: initialColumns },
        "",
        DocTypes.table
      );
    default:
      return null;
  }
};

export default function BstFabs(props) {
  const { writePermission, handleCreateSubDoc, expandAll } = props;

  function capitalize(str) {
    if (!str) return str; // Return empty if input is empty
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey) {
        switch (event.key) {
          case "d":
            console.log(event.key);
            handleCreateNewSubDoc(DocTypes.document, handleCreateSubDoc); // Alt + D
            event.preventDefault();
            break;
          case "r":
            handleCreateNewSubDoc(DocTypes.draw, handleCreateSubDoc); // Alt + R
            break;
          case "s":
            handleCreateNewSubDoc(DocTypes.sheet, handleCreateSubDoc); // Alt + S
            event.preventDefault();
            break;
          case "g":
            handleCreateNewSubDoc(DocTypes.table, handleCreateSubDoc); // Alt + g
            break;
          case "f":
            handleCreateNewSubDoc(DocTypes.flow, handleCreateSubDoc); // Alt + F
            event.preventDefault();
            break;
          case "m":
            handleCreateNewSubDoc(DocTypes.media, handleCreateSubDoc); // Alt + M
            event.preventDefault();
            break;
          case "c":
            handleCreateNewSubDoc(DocTypes.code, handleCreateSubDoc); // Alt + C
            event.preventDefault();
            break;
          case "a":
            handleCreateNewSubDoc(DocTypes.api, handleCreateSubDoc); // Alt + a
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ mb: 1, ml: 5 }}>
        <Tooltip title={capitalize(DocTypes.document) + " (alt + d)"} arrow>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.document, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="primary"
            sx={{ m: 0, boxShadow: 3 }}
          >
            <NoteAddIcon />
          </Fab>
        </Tooltip>
        {/* <Tooltip title={capitalize(DocTypes.sheet) + " (alt + s)"} arrow>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.sheet, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="secondary"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <PostAddIcon />
          </Fab>
        </Tooltip> */}
        <Tooltip title={capitalize(DocTypes.table) + " (alt + g)"}>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.table, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="secondary"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <GridOnIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={capitalize(DocTypes.draw) + " (alt + r)"} arrow>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.draw, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="yellow"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <DrawIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={capitalize(DocTypes.flow) + " (alt + f)"}>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.flow, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="green"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <AccountTreeIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={capitalize(DocTypes.media) + " (alt + m)"}>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.media, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="orange"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <PermMediaIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={capitalize(DocTypes.code) + " (alt + c)"}>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.code, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="grey"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <CodeIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={capitalize(DocTypes.api) + " (alt + a)"}>
          <Fab
            disabled={!writePermission}
            onClick={() =>
              handleCreateNewSubDoc(DocTypes.api, handleCreateSubDoc)
            }
            size="small"
            variant="contained"
            color="purple"
            sx={{ m: 0, ml: 1, boxShadow: 2 }}
          >
            <ApiIcon />
          </Fab>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title={"Expand All"} arrow>
          <IconButton onClick={() => expandAll(true)}>
            <UnfoldMoreIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Minimize All"} arrow>
          <IconButton onClick={() => expandAll(false)}>
            <UnfoldLessIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}
