import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import {
  createRef,
  React,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// components
import Layout from "./Layout/layout";
import axiosInstance from "./axiosInstance";
import { Box, IconButton } from "@mui/material";
// Bst Data Forms
import BstCode from "./BstCode";
import BstDraw from "./BstDraw";
import BstSheet from "./BstSheet";
import BstMedia from "./BstMedia";
import BstEditor from "./BstEditor";
import BstFlow from "./Flow/BstFlow";
// utils
import { companyName, DocTypes } from "./constants";
import BstDeleteConfirm from "./BstDeleteConfirm";
import BstShare from "./BstShare";
import BstFabs from "./BstFabs";
import BstMainHeader from "./BstMainHeader";
import BstApiMain from "./BstApi/main";
import BstTable from "./BstTable";
//icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Bst() {
  const { id: documentId } = useParams();
  const [documentName, setDocumentName] = useState();
  const [subDocuments, setSubDocuments] = useState([]);
  const subDocRefs = useRef([]);
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState("read");
  const [userData, setUserData] = useState({ name: "", email: "" });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const [expand, setExpand] = useState({});
  const [fullScreen, setFullScreen] = useState({});
  const handleShareOpen = () => setShareOpen(true);
  const handleShareClose = () => setShareOpen(false);
  // eslint-disable-next-line no-unused-vars
  const [copiedSubDoc, setCopiedSubDoc] = useState(null);
  const [selectedSubDocId, setSelectedSubdocId] = useState(null);
  const [selectedSubDocIndex, setSelectedSubdocIndex] = useState(0);
  const selectedRef = useRef();

  useEffect(() => {
    document.title = documentName + "-" + companyName;
  });

  // handleFocus
  const handleFocus = () => {
    if (selectedRef.current) {
      selectedRef.current.focus();
    }
  };

  // handleUnFocus
  const handleUnFocus = () => {
    if (selectedRef.current) {
      selectedRef.current.unfocus();
    }
  };

  // Load document
  useEffect(() => {
    const loadDocument = async () => {
      try {
        const response = await axiosInstance.post(`/doc/${documentId}`);
        const { doc, subDocs } = response.data;
        setDocumentName(doc.name);
        setPermission(doc.accessLevel);
        setUserData({ name: doc.owner.name, email: doc.owner.email });
        setExpand(loadExpand(subDocs));
        setSubDocuments(subDocs);
        setSelectedSubdocIndex(0);
        setSelectedSubdocId(subDocs?.[0]?._id);
      } catch (error) {
        console.log("Failed to load the document:", error);
      }
    };

    if (documentId) {
      loadDocument();
    }
  }, [documentId]);

  useEffect(() => {
    if (subDocuments.length !== subDocRefs.current.length) {
      subDocRefs.current = subDocuments.map(() => createRef());
    }
  }, [subDocuments]);

  const handleOpenConfirm = (id) => {
    setDocToDelete(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setDocToDelete(null);
  };

  const handleConfirmDelete = () => {
    handleSubDocumentDelete(docToDelete);
    handleCloseConfirm();
  };

  // shortcut to delete selected document
  useEffect(() => {
    const handleOpenConfirm = (id) => {
      setDocToDelete(id);
      setConfirmOpen(true);
    };
    const handleKeyDown = (event) => {
      if (event.altKey) {
        switch (event.key) {
          case "w":
            handleOpenConfirm(selectedSubDocId);
            event.preventDefault();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const loadExpand = (subDocuments) => {
    let expand = {};
    for (let i in subDocuments) {
      const subDoc = subDocuments[i];
      expand[subDoc._id] = subDoc.isExpanded;
    }
    return expand;
  };

  const loadFullscreen = (subDocuments) => {
    let fullScreen = {};
    for (let i in subDocuments) {
      const subDoc = subDocuments[i];
      fullScreen[subDoc._id] = false;
    }
    return fullScreen;
  };

  // handle heading change
  const handleHeadingChange = async (heading, subDocumentId) => {
    try {
      await axiosInstance.put(`/sub-doc/${subDocumentId}/`, {
        heading: heading,
      });
    } catch (error) {
      console.error("Failed to update document heading:", error);
    }
  };

  // create new sub doc
  const handleCreateSubDoc = async (data, heading, docType) => {
    try {
      const response = await axiosInstance.post("sub-doc", {
        heading: heading,
        data: data,
        docType: docType,
        documentId: documentId,
      });
      const { subDocuments } = response.data;
      setExpand(loadExpand(subDocuments));
      setFullScreen(loadFullscreen(subDocuments));
      setSubDocuments(subDocuments);
    } catch (error) {
      console.log(error);
    }
  };

  // sub doucument delete
  const handleSubDocumentDelete = async (docId) => {
    try {
      const response = await axiosInstance.delete(`/sub-doc/${docId}`);
      const { subDocuments } = response.data;
      const updatedSubDocs = subDocuments.map((doc, index) => ({
        ...doc,
        order: index,
      }));
      setSubDocuments(updatedSubDocs);
      saveNewOrder(updatedSubDocs);
      const newSelectedSubDocIndex =
        selectedSubDocIndex > 0 ? selectedSubDocIndex - 1 : 0;
      setSelectedSubdocId(newSelectedSubDocIndex);
      setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
    } catch (error) {
      console.error("Failed to delete document", error);
    }
  };

  // save documents
  const saveDocument = useCallback(async (data, subDocumentId) => {
    try {
      await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
        data: data,
      });
      setSubDocuments((subDocuments) =>
        subDocuments.map((item) =>
          item._id === subDocumentId ? { ...item, data: data } : item
        )
      );
    } catch (error) {
      console.error("Failed to save the document:", error);
    }
  }, []);

  // handle slides

  const handleExpand = async (subDocumentId, minimize) => {
    setExpand((prevState) => ({
      ...prevState,
      [subDocumentId]: minimize,
    }));
    try {
      await axiosInstance.put(`/sub-doc/${subDocumentId}`, {
        isExpanded: minimize,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFullScreen = async (subDocumentId, state) => {
    setFullScreen((prevState) => ({
      ...prevState,
      [subDocumentId]: state,
    }));
  };

  const writePermission = permission === "write";

  const handlSelectSubDoc = (data, subDoc) => {
    setCopiedSubDoc(data);
    setSelectedSubdocId(subDoc._id);
    setSelectedSubdocIndex(subDoc.order);
  };

  const renderBstComponent = (subDocument, expand, provided) => {
    if (subDocument === "") return null;

    // Common props for all components
    const commonProps = {
      subDocument: subDocument,
      handleHeadingChange: handleHeadingChange,
      handleSubDocumentDelete: handleSubDocumentDelete,
      handleOpenConfirm: handleOpenConfirm,
      writePermission: writePermission,
      handleExpand: handleExpand,
      fullScreen: fullScreen,
      selectedSubDocId,
      handleFullScreen: handleFullScreen,
      expand: expand,
      handlSelectSubDoc: handlSelectSubDoc,
      saveDocument: saveDocument,
      handleFocus: handleFocus,
      handleUnFocus: handleUnFocus,
      provided: provided,
      open: open,
    };

    switch (subDocument.docType) {
      case DocTypes.document:
        return (
          <BstEditor
            ref={selectedRef}
            {...commonProps}
            docType={DocTypes.document}
          />
        );
      case DocTypes.sheet:
        return <BstSheet {...commonProps} docType={DocTypes.sheet} />;
      case DocTypes.flow:
        return <BstFlow {...commonProps} docType={DocTypes.flow} />;
      case DocTypes.draw:
        return <BstDraw {...commonProps} docType={DocTypes.draw} />;
      case DocTypes.media:
        return <BstMedia {...commonProps} docType={DocTypes.media} />;
      case DocTypes.code:
        return <BstCode {...commonProps} docType={DocTypes.code} />;
      case DocTypes.api:
        return <BstApiMain {...commonProps} docType={DocTypes.api} />;
      case DocTypes.table:
        return <BstTable {...commonProps} docType={DocTypes.table} />;
      default:
        return null;
    }
  };

  const expandAll = async (expand) => {
    const updatedExpand = {};
    subDocuments.forEach((subDoc) => {
      updatedExpand[subDoc._id] = expand;
    });

    // Update state in one batch
    setExpand(updatedExpand);

    // Update all documents in parallel
    try {
      await Promise.all(
        subDocuments.map((subDoc) => handleExpand(subDoc._id, expand))
      );
    } catch (error) {
      console.log("Error expanding all:", error);
    }
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // If no destination, return
    if (!destination) return;

    // If the item is dropped at the same place, return
    if (destination.index === source.index) return;

    // Rearrange the list based on the drag result
    const reorderedSubDocs = Array.from(subDocuments);
    const [removed] = reorderedSubDocs.splice(source.index, 1);
    reorderedSubDocs.splice(destination.index, 0, removed);

    // Update the order property based on the new position
    const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
      ...doc,
      order: index,
    }));

    setSubDocuments(updatedSubDocs);

    saveNewOrder(updatedSubDocs);
  };

  // API call to save the new order
  const saveNewOrder = async (updatedSubDocs) => {
    try {
      await axiosInstance.patch("/sub-doc/save-order", {
        subDocs: updatedSubDocs,
      });
      console.log("Order saved successfully");
    } catch (error) {
      console.error("Error saving order:", error);
    }
  };

  // move up and down in the documents
  // useEffect to handle keyboard shortcuts
  useEffect(() => {
    const scrollTo = (newSelectedSubDocIndex) => {
      // Scroll the selected sub-document into view
      subDocRefs.current[newSelectedSubDocIndex]?.current?.scrollIntoView({
        behavior: "smooth", // Scroll smoothly
        block: "start", // Scroll to the center of the view
      });
    };
    const handleKeyDown = (e) => {
      let newSelectedSubDocIndex = selectedSubDocIndex || 0;
      const isAnyFullScreen = Object.values(fullScreen).some(
        (value) => value === true
      );

      if (!open) {
        if (
          e.ctrlKey &&
          e.key === "ArrowDown" &&
          selectedSubDocIndex < subDocuments.length - 1 &&
          !isAnyFullScreen
        ) {
          newSelectedSubDocIndex = selectedSubDocIndex + 1;
          handleUnFocus();
          setSelectedSubdocIndex(newSelectedSubDocIndex);
          setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
          scrollTo(newSelectedSubDocIndex);
        } else if (
          e.ctrlKey &&
          e.key === "ArrowUp" &&
          selectedSubDocIndex > 0 &&
          !isAnyFullScreen
        ) {
          newSelectedSubDocIndex = selectedSubDocIndex - 1;
          handleUnFocus();
          setSelectedSubdocIndex(newSelectedSubDocIndex);
          setSelectedSubdocId(subDocuments[newSelectedSubDocIndex]?._id);
          scrollTo(newSelectedSubDocIndex);
        }

        // Reorder sub-documents with Ctrl + Alt + ArrowUp / ArrowDown
        else if (
          e.altKey &&
          e.key === "ArrowDown" &&
          selectedSubDocIndex < subDocuments.length - 1
        ) {
          console.log("hello");
          const reorderedSubDocs = Array.from(subDocuments);
          // Move selected doc down
          const [movedDoc] = reorderedSubDocs.splice(selectedSubDocIndex, 1);
          reorderedSubDocs.splice(selectedSubDocIndex + 1, 0, movedDoc);

          const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
            ...doc,
            order: index,
          }));

          setSubDocuments(updatedSubDocs);
          setSelectedSubdocIndex(selectedSubDocIndex + 1);
          setSelectedSubdocId(updatedSubDocs[selectedSubDocIndex + 1]._id);
          saveNewOrder(updatedSubDocs);
          scrollTo(selectedSubDocIndex + 1);
        } else if (e.altKey && e.key === "ArrowUp" && selectedSubDocIndex > 0) {
          const reorderedSubDocs = Array.from(subDocuments);
          // Move selected doc up
          const [movedDoc] = reorderedSubDocs.splice(selectedSubDocIndex, 1);
          reorderedSubDocs.splice(selectedSubDocIndex - 1, 0, movedDoc);

          const updatedSubDocs = reorderedSubDocs.map((doc, index) => ({
            ...doc,
            order: index,
          }));

          setSubDocuments(updatedSubDocs);
          setSelectedSubdocIndex(selectedSubDocIndex - 1);
          setSelectedSubdocId(updatedSubDocs[selectedSubDocIndex - 1]._id);
          saveNewOrder(updatedSubDocs);
          scrollTo(selectedSubDocIndex - 1);
        }
      }
    };

    const expandAll = async (expand) => {
      // Create a new expanded state for all sub-documents
      const updatedExpand = subDocuments.reduce((acc, subDoc) => {
        acc[subDoc._id] = expand;
        return acc;
      }, {});

      // Update the state immediately
      setExpand(updatedExpand);

      // Perform the API updates in parallel without blocking the state update
      try {
        await Promise.all(
          subDocuments.map((subDoc) => handleExpand(subDoc._id, expand))
        );
      } catch (error) {
        console.error("Error expanding all:", error);
      }
    };

    const handleExpandShortCuts = (e) => {
      if (e.ctrlKey && e.shiftKey) {
        if (e.key === "ArrowRight") {
          expandAll(true);
        } else if (e.key === "ArrowLeft") {
          expandAll(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keydown", handleExpandShortCuts);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedSubDocId, selectedSubDocIndex, subDocuments, fullScreen, open]);

  return (
    <Box>
      <Layout
        expand={expand}
        selectedSubDocId={selectedSubDocId}
        subDocuments={subDocuments}
        setSelectedSubdocId={setSelectedSubdocId}
        setSelectedSubdocIndex={setSelectedSubdocIndex}
        open={open}
        setOpen={setOpen}
      >
        <Box sx={{ mb: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {/* Top text and search bar */}
              <BstMainHeader
                documentName={documentName}
                subDocuments={subDocuments}
                setDocumentName={setDocumentName}
                writePermission={writePermission}
                handleCreateSubDoc={handleCreateSubDoc}
                documentId={documentId}
                userData={userData}
                handleShareOpen={handleShareOpen}
              />
              <Box sx={{ mt: 2 }} />
              {/* Bst Create Buttons */}
              <BstFabs
                writePermission={writePermission}
                handleCreateSubDoc={handleCreateSubDoc}
                expandAll={expandAll}
              />
              {/* Bst Components */}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="subDoc">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {subDocuments.map((subDocument, index) => {
                        return (
                          <Draggable
                            key={subDocument._id}
                            draggableId={subDocument._id}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                sx={{ display: "flex" }}
                                ref={subDocRefs.current[index]}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    handleExpand(
                                      subDocument._id,
                                      !expand[subDocument._id]
                                    );
                                    e.target.blur();
                                  }}
                                  sx={{
                                    maxHeight: 50,
                                  }}
                                >
                                  {expand[subDocument._id] ? (
                                    <KeyboardArrowDownIcon />
                                  ) : (
                                    <KeyboardArrowRightIcon />
                                  )}
                                </IconButton>
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  width="100%"
                                  borderRadius="10px"
                                  sx={{ mt: 1 }}
                                  boxShadow={
                                    // selectedSubDocId === subDocument._id
                                    index === selectedSubDocIndex
                                      ? "0px 2px 2px rgba(0, 0, 0, 0.5)"
                                      : "none"
                                  }
                                >
                                  {renderBstComponent(
                                    subDocument,
                                    expand,
                                    provided
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
          <ToastContainer />
        </Box>
      </Layout>

      <BstDeleteConfirm
        confirmOpen={confirmOpen}
        handleCloseConfirm={handleCloseConfirm}
        handleConfirmDelete={handleConfirmDelete}
      />

      <BstShare
        shareOpen={shareOpen}
        handleShareOpen={handleShareOpen}
        handleShareClose={handleShareClose}
        documentId={documentId}
      />
    </Box>
  );
}
